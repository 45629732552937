/* roboto-regular */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local("Roboto"), url("../fonts/roboto-regular.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* roboto-500 */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: local("Roboto"), url("../fonts/roboto-500.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* roboto-700 */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local("Roboto"), url("../fonts/roboto-700.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: Shabnam;
	src: url("../fonts/Shabnam-FD.woff") format("woff");
	font-weight: normal;
}

@font-face {
	font-family: Shabnam;
	src: url("../fonts/Shabnam-Bold-FD.woff") format("woff");
	font-weight: 700;
}

@font-face {
	font-family: Shabnam;
	src: url("../fonts/Shabnam-Medium-FD.woff") format("woff");
	font-weight: 600;
}

@font-face {
	font-family: Shabnam;
	src: url("../fonts/Shabnam-Light-FD.woff") format("woff");
	font-weight: 300;
}
@font-face {
	font-family: Shabnam;
	src: url("../fonts/Shabnam-Thin-FD.woff") format("woff");
	font-weight: 100;
}

/* JetBrains Mono */

@font-face {
	font-family: JetBrainsMono;
	src: url("../fonts/JetBrainsMono-Regular.woff2") format("woff2");
	font-weight: 400;
}
@font-face {
	font-family: JetBrainsMono;
	src: url("../fonts/JetBrainsMono-Bold.woff2") format("woff2");
	font-weight: 700;
}
